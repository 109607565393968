import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import styled from 'styled-components';

const MotionDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const AnimationFade = ({ rootMargin, custom, duration, children }: any) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: rootMargin,
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.3,
          duration: duration,
        },
      }));
    }
  }, [controls, inView]);

  return (
    <MotionDiv ref={ref} initial={{ opacity: 0 }} animate={controls} custom={custom}>
      {children}
    </MotionDiv>
  );
};

import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Props } from 'types/types'

const Container = styled.div<Props>`
  position: relative;
  z-index: 20;
  width: 12rem;
  height: 100%;
  display: flex;
  align-items: center;
  filter: ${(props) => (props.filters ? 'invert(100%)' : 'invert(0%)')};

  a {
    display: flex;
    align-items: center;
  }
`

const Icons = styled.img`
  width: 4rem;
  height: 4rem;
  filter: ${({ theme: { filters } }) => filters.pink};
`

const Logo = ({ filters }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          sites {
            linkTo
            icons {
              src {
                publicURL
              }
              alt
            }
          }
        }
      }
    }
  `)

  const { linkTo, icons } = datas.markdownRemark.frontmatter.sites

  return (
    <Container filters={filters}>
      <AnchorLink to={linkTo} stripHash>
        <Icons src={icons.src.publicURL} alt={icons.alt} />
      </AnchorLink>
    </Container>
  )
}

export default Logo

import styled, { css } from 'styled-components'
import { Props } from 'types/types'

export const Wrapper = styled.div`
  max-width: 1250px;
  width: 90%;
  margin: 0 auto;
  height: ${(props: Props) => (props.height ? '100%' : null)};
`

export const GridBoxTwo = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-auto-rows: auto;
`
export const GridBoxThree = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
  grid-auto-rows: auto;
`

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FlexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

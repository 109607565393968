import React from 'react'
import styled from 'styled-components'

const Container = styled.section`
  position: relative;
  z-index: 2;
  width: 100%;
  height: fit-content;
`

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Copy = styled.p`
  margin-bottom: 0;
  font-size: 1.2rem;
  color: ${({ theme: { colors } }) => colors.white};
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        footer: {
          title: string
        }
      }
    }
  }
}

const Footer = ({ datas }: Datas) => {
  const { title } = datas.markdownRemark.frontmatter.footer

  return (
    <Container>
      <FlexBox>
        <Copy>{`${title} © ${new Date().getFullYear()}, Made by JT `}</Copy>
      </FlexBox>
    </Container>
  )
}

export default Footer

import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { maxScreen } from 'styles/_breakpoints'

import Logo from 'components/Logo/Logo'
import Social from 'components/Social/Social'

const Container = styled.section`
  position: relative;
  z-index: 2;
  width: 100%;
  height: fit-content;
  padding-bottom: 5rem;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-auto-rows: auto;
  gap: 3rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme: { colors } }) => colors.white};

  img {
    width: 4rem !important;
    height: 4rem !important;
    margin-right: 1rem;

    @media ${maxScreen.smart} {
      width: 3rem !important;
      height: 3rem !important;
    }
  }

  @media ${maxScreen.tablet} {
    margin-bottom: 2rem;
  }
`

const Title = styled.h3`
  margin-bottom: 1rem;
  font-size: 3.5rem;
`

const Decscription = styled.div`
  width: 60%;
  margin: 1.5rem 0 0;

  P {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 2.25rem;
  }

  @media ${maxScreen.desktop} {
    width: 85%;
  }
`

const Text = styled.p`
  margin-bottom: 0;
  font-size: 1.3rem;
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        footer: {
          id: number
          header: {
            id: number
            title: string
            description: string
          }
          follow: {
            id: number
            title: string
          }
          infos: {
            id: number
            title: string
            email: string
          }
        }
      }
    }
  }
}

const FooterInfo = ({ datas }: Datas) => {
  const { header, follow, infos } = datas.markdownRemark.frontmatter.footer

  return (
    <Container>
      <GridBox>
        <Box>
          <Logo />
          <Decscription>
            <ReactMarkdown>{header.description}</ReactMarkdown>
          </Decscription>
        </Box>
        <Box>
          <Title>{follow.title}</Title>
          <Social direction='row' />
        </Box>
        <Box>
          <Title>{infos.title}</Title>
          <Text>{infos.email}</Text>
        </Box>
      </GridBox>
    </Container>
  )
}

export default FooterInfo

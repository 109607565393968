import React from 'react'

import { ThemeProvider } from 'styled-components'
import bg from 'assets/images/sicum-footer.jpg'

export const theme = {
  colors: {
    white: '#EFEFEF',
    black: '#1E1E1E',
    opblack: '#00000090',
    red: '#dc3546',
    gray: '#C9C9C9',
    lightgray: '#dfdfdfcd',
    darkgray: '#4c464d',
    green: '#41b883',
    pink: '#FE6987',
    oppink: '#fe698738',
  },
  fonts: {
    bebasneue: "'Bebas Neue', serif",
    montserrat: "'Montserrat', sans-serif",
  },
  backgrounds: {
    pattern: `url(${bg})`,
    google: '#4c8bf5',
  },
  filters: {
    pink: 'invert(47%) sepia(89%) saturate(741%) hue-rotate(311deg) brightness(103%) contrast(99%)',
  },
}

const Theme = ({ children }: any) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme

import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Props } from 'types/types'

import { FlexCenter } from 'styles/_mixins'
import { deviceScreen, maxScreen } from 'styles/_breakpoints'

export const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: fit-content;
`

export const Bg = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 7rem;
  background: ${({ theme: { backgrounds } }) => backgrounds.pattern};
  background-position: top;
  background-size: cover;
  transform: ${(props) => (props.backgrounds ? 'translateY(0)' : 'translateY(-100%)')};
  transition: all 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.opblack};
  }

  @media ${maxScreen.tablet} {
    display: none;
  }
`

export const NavMenu = styled.nav`
  position: relative;
  z-index: 11;
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
`

export const NavList = styled.ul<Props>`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${maxScreen.tablet} {
    ${FlexCenter}
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.opblack};
    visibility: ${({ click }) => (click ? 'visible' : 'hidden')};
    opacity: ${({ click }) => (click ? '1' : '0')};
    transition: all 0.5s ease;
  }
`

export const NavItems = styled.li<Props>`
  margin-right: 5rem;
  text-transform: capitalize;

  @media ${maxScreen.smart} {
    margin-right: 0;
    padding-bottom: 2rem;
  }

  @media ${deviceScreen.smland} {
    margin-right: 0;
    padding-bottom: 1rem;
  }

  @media ${deviceScreen.tablet} {
    margin-right: 0;
    padding-bottom: 2rem;
  }
`

export const NavLink = styled(AnchorLink)`
  font-size: 1.9rem;
  color: ${({ theme: { colors } }) => colors.white};

  @media ${maxScreen.smart} {
    margin: 0;
    font-size: 3rem;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }
  }

  @media ${deviceScreen.smland} {
    margin: 0;
    font-size: 2.25rem;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }
  }

  @media ${deviceScreen.tablet} {
    margin: 0;
    font-size: 3rem;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }
  }
`

export const NavBox = styled.li`
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${maxScreen.tablet} {
    justify-content: center;
    margin-top: 0.5rem;
  }
`

import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import { Wrapper } from 'styles/_mixins'

import FooterBG from 'components/Footer/FooterBG'
import FooterInfo from 'components/Footer/FooterInfo'
import FooterCopy from 'components/Footer/FooterCopy'
import Top from 'components/Top/Top'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 5rem 0;
  background: ${({ theme: { colors } }) => colors.black};
`

const Footer = () => {
  const [ref, inView] = useInView({
    rootMargin: '-100px 0px',
  })

  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          footer {
            id
            title
            images {
              src {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                }
              }
              alt
            }
            header {
              id
              title
              description
              icons {
                alt
                src {
                  publicURL
                }
              }
            }
            follow {
              id
              title
            }
            infos {
              id
              title
              email
            }
          }
        }
      }
    }
  `)

  return (
    <Container ref={ref}>
      <FooterBG datas={datas} />
      <Wrapper>
        <FooterInfo datas={datas} />
        <FooterCopy datas={datas} />
        {inView && <Top />}
      </Wrapper>
    </Container>
  )
}

export default Footer

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100vh;
    font-size: 62.5%;
  }

  body {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    font-family: ${({ theme: { fonts } }) => fonts.montserrat};
    color: ${({ theme: { colors } }) => colors.black};
    background: ${({ theme: { colors } }) => colors.white};
  }

  h1, h2, h3, h4, h5, h6 {
    margin:0; 
    font-size:inherit; 
    font-weight:inherit;
    text-transform: uppercase;
    line-height: 1;
    font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  }

  h1 {
    font-size: clamp(14rem, 22vw, 25rem);
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.white};
  }

  h2 {
    font-size: clamp(3.5rem, 12vw, 7rem);
  }

  h3 {
    font-size: 4rem;
  }

  a {
    text-decoration: none !important;
    color: inherit;
    cursor: pointer;
    font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  }

  p {
    margin: 0;
  }

  ul, ol, dd {
    margin:0; 
    padding:0; 
    list-style:none;
  }

  button {
    background:transparent; 
    color:inherit; 
    border-width:0; 
    padding:0; 
    cursor:pointer;
  }

  figure {
    margin:0;
  }

  input::-moz-focus-inner {
    border:0; 
    padding:0; 
    margin:0;
  }

  cite {
    font-style:normal;
  }

  fieldset {
    border-width:0; 
    padding:0; 
    margin:0;
  }
`
export default GlobalStyle
